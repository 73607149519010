// extracted by mini-css-extract-plugin
export var content = "AccessSection-module--content--048ef";
export var description = "AccessSection-module--description--a666e";
export var featureSection = "AccessSection-module--featureSection--30f7d";
export var featureSectionInner = "AccessSection-module--featureSectionInner--1268f";
export var featureSectionPrimary = "AccessSection-module--featureSectionPrimary--a4b44";
export var featureSectionSecondary = "AccessSection-module--featureSectionSecondary--5d60c";
export var head = "AccessSection-module--head--203aa";
export var headSubtext = "AccessSection-module--headSubtext--c90fe";
export var headText = "AccessSection-module--headText--9590d";
export var map = "AccessSection-module--map--8c097";
export var title = "AccessSection-module--title--6e269";