import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import H1CenterSection from '../components/H1CenterSection/H1CenterSection';
import AccessSectionLeft from '../components/AccessSectionLeft/AccessSectionLeft';
import AccessSectionRight from '../components/AccessSectionRight/AccessSectionRight';

const WazaiAccessPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <H1CenterSection title="アクセス" />
        <AccessSectionRight
          title="金田店"
          description={`
          団らん処「和菜屋」／デイサービス「和才屋」
          【お車でお越しの方へ】
          西日本シティ銀行　小倉金田支店ウラ
          無料駐車場：6台
          〒803-0816
          北九州市小倉北区金田1-1-6
          UR金田団地1階
          ☎093-953-7570／093-562-7420
          営業時間：10:30~18:30
          店休日：日曜・祝日

        `}
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.427603159839!2d130.8648527764333!3d33.87863962687582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c7580448812b%3A0x10ec83871483d480!2z5Zuj44KJ44KT5YemIOWSjOiPnOWxiyDph5HnlLDlupc!5e0!3m2!1sen!2sjp!4v1706945854224!5m2!1sen!2sjp"
        />
        <AccessSectionLeft
          title="木町店"
          description={`
          団らん処「和菜屋」／デイサービス「和才屋」
          【お車でお越しの方へ】
          国道３号線木町交番前交差点そば
          無料駐車場：4台
          〒803-0851
          北九州市小倉北区木町4丁目3-10
          アスティー花ごよみ1階／2階
          ☎093-383-0520／093-383-0390
          営業時間：10:30～17:00
          店休日：日曜・祝日
        `}
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.6639235167863!2d130.8633331764331!3d33.872551127195955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c75a5a39db5b%3A0xcd4d35ec63e03f31!2z5Zuj44KJ44KT5YemIOWSjOiPnOWxiyDmnKjnlLrlupc!5e0!3m2!1sen!2sjp!4v1706944320098!5m2!1sen!2sjp"
        />
      </Layout>
    </div>
  );
};

export default WazaiAccessPage;

export const Head = () => (
  <Seo title="アクセス情報" description="団欒処 和菜屋のアクセス情報です。" />
);
